#header nav {
    background: transparent;
    .page.left { float: left; }
    .page.right { float: right; }
    .page.projects-li {
        margin: 0 5px;
        position: relative;
        &.left .projects {
            left: 0;
            right: auto;
        }
        &:hover .projects {
            opacity: 1;
            top: 6px;
            height: auto;
            .project { position: static; }
        }
        .projects {
            min-width: 200%;
            top: -10px;
            right: 0;
            position: absolute;
            height: 0;
            opacity: 0;
            transition: opacity 300ms, top 500ms;

            .project {
                display: block;
                position: absolute;
                top: -999px;
                margin: 0;
            }
        }
    }
}
