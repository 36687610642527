.icon.heart {
    width: 9px;
    height: 9px;
    border-left: solid 1px currentColor;
    border-bottom: solid 1px currentColor;
    background-color: currentColor;
    transform: rotate(-45deg);

    &:after,
    &:before {
        border-top: solid 1px currentColor;
        border-right: solid 1px currentColor;
        background-color: currentColor;
    }

    &:after {
        top: 0;
        left: 8px;
        width: 5px;
        height: 8px;
        border-radius: 0 5px 5px 0;
        border-bottom: solid 1px currentColor;
    }

    &:before {
        top: -5px;
        left: -1px;
        width: 8px;
        height: 5px;
        border-radius: 5px 5px 0 0;
        border-left: solid 1px currentColor;
    }
}
