@import "jekyll-theme-midnight";
@import "header_nav";
@import "pagination";
@import "icons";
@import "post";

.rtl {
    direction: rtl;
    .highlighter-rouge,
    .pubdate {
        direction: ltr;
    }
    code,
    .pubdate {
        display: inline-block;
    }
}
