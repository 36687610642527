// grabed from https://github.com/wentin/cssicon

.icon {
    position: relative;
    display: inline-block;
    margin: 0 2px;

    &:before,
    &:after,
    i,
    i:after,
    i:before {
        content: '';
        position: absolute;
    }
}

@import "./icon-heart";
@import "./icon-tag";
@import "./icon-link";
