@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/OpenSans-Light-webfont.eot');
    src: url('../fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Light-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Light-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Light-webfont.svg#OpenSansLight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansLightItalic';
    src: url('../fonts/OpenSans-LightItalic-webfont.eot');
    src: url('../fonts/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-LightItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-LightItalic-webfont.svg#OpenSansLightItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Regular-webfont.svg#OpenSansRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing:antialiased;
}

@font-face {
    font-family: 'OpenSansItalic';
    src: url('../fonts/OpenSans-Italic-webfont.eot');
    src: url('../fonts/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Italic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Italic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Italic-webfont.svg#OpenSansItalic') format('svg');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing:antialiased;
}

@font-face {
    font-family: 'OpenSansSemibold';
    src: url('../fonts/OpenSans-Semibold-webfont.eot');
    src: url('../fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Semibold-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Semibold-webfont.svg#OpenSansSemibold') format('svg');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing:antialiased;
}

@font-face {
    font-family: 'OpenSansSemiboldItalic';
    src: url('../fonts/OpenSans-SemiboldItalic-webfont.eot');
    src: url('../fonts/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-SemiboldItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-SemiboldItalic-webfont.svg#OpenSansSemiboldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing:antialiased;
}

@font-face {
    font-family: 'OpenSansBold';
    src: url('../fonts/OpenSans-Bold-webfont.eot');
    src: url('../fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Bold-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Bold-webfont.svg#OpenSansBold') format('svg');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing:antialiased;
}

@font-face {
    font-family: 'OpenSansBoldItalic';
    src: url('../fonts/OpenSans-BoldItalic-webfont.eot');
    src: url('../fonts/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-BoldItalic-webfont.svg#OpenSansBoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing:antialiased;
}
