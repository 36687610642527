.icon.tag {
    width: 9px;
    height: 9px;
    border-radius: 1px 1px 1px 0;
    border-left: solid 1px currentColor;
    border-top: solid 1px currentColor;

    &,
    &:after,
    &:before {
        background-color: currentColor;
    }

    &:after {
        left: 3px;
        top: 3px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        color: white;
    }

    &:before {
        left: 1px;
        top: 1px;
        width: 9px;
        height: 10px;
        border-radius: 1px;
        border-left: solid 1px currentColor;
        border-right: solid 1px currentColor;
        border-bottom: solid 1px currentColor;
        transform: rotate(-45deg);
    }
}
