.post-date {
    margin-bottom: 0;
    opacity: 0.6;
}

.post-categories {
    margin-bottom: 20px;
}

.post-tags {
    padding-bottom: 20px;
    .tag:not(.icon) {
        @extend pre;
        margin: 0;
        padding: 5px 10px;
        display: inline-block;
    }
}
